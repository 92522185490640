.contact-modal {
  .contact-modal-body {
    width: 300px;
    height: 350px;
    background-color: #f5f8f9;
    border-radius: 5px;
    position: absolute;
    // top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: auto;
    @media (max-width: 992px) {
      top: 0 !important;
      bottom: 0 !important;
    }
    .contact-modal-content {
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .contact-modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
      .contact-modal-title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 1rem 0;
      }
      .contact-modal-message {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        .contact-modal-success-icon {
          height: 80px;
          width: 80px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
