.notfound-container {
  .header {
    height: 60px;
    nav {
      background: rgba(0, 67, 139, 0.85) !important;
    }
  }
  .notfound-pg {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
    background-color: #f4f7fa;
    .notfound-icon {
      height: 120px;
      width: 120px;
    }
    .notfound-title {
      margin-top: 40px;
      font-size: 24px;
    }
    .notfound-subtitle {
      font-size: 16px;
      margin-top: 10px;
      .notfound-link {
      }
    }
  }
}
