.home-page {
  .hero-section {
    &.background-img {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: top !important;
    }
  }

  .home-hero-text-wrap {
    @media (min-width: 1200px) {
      margin-bottom: 100px;
    }

    @media (min-width: 992px) {
      margin-bottom: 80px;
    }

    .lead {
      margin-bottom: 1rem !important;
    }
  }

  .client-section-wrap {
    @media (max-width: 992px) {
      display: flex !important;
      flex-direction: column !important;

      .lead {
        margin-right: 0 !important;
      }
    }

    .home-tech-partners {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: space-between !important;
      align-items: center;

      li.list-inline-item {
        padding-right: 40px;
      }

      @media (max-width: 1200px) {
        li.list-inline-item {
          padding-right: 20px;
        }
      }

      @media (max-width: 992px) {
        justify-content: center !important;

        li.list-inline-item {
          padding-right: 20px;
          padding: 0 10px !important;
          margin: 0 !important;
        }
      }
    }
  }

  .sign-up-form-wrap {
    @media (max-width: 768px) {
      margin-top: 0 !important;
    }
  }

  .single-promo {
    .promo-img {
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  }
}

.contact-modal {
  .contact-modal-body {
    width: 300px;
    height: 350px;
    background-color: #f5f8f9;
    border-radius: 5px;
    position: absolute;
    // top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: auto;

    .contact-modal-content {
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      .contact-modal-title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 1rem 0;
      }

      .contact-modal-message {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;

        .contact-modal-success-icon {
          height: 80px;
          width: 80px;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.case-studies-nav {
  margin: unset !important;
  padding: 5px 15px !important;
  text-align: center !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
}

.case-study-item-container {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  .case-study-item {
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    position: relative;
    margin: 3px;
    margin-top: 10px;
    text-decoration: none;
    color: inherit;
    min-height: 440px; // fixed height for each card
    overflow: hidden; // hides overflowing content
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
      transform: translateY(-5px); // Moves the item slightly upwards
      text-decoration: none;
    }

    .case-study-item-img {
      width: 100%;
      height: 200px; // adjust this value as needed
      object-fit: cover;
      object-position: center;
    }

    .case-study-item-desc {
      line-height: 2rem;
      font-size: 16px;
      text-decoration: none;
    }

    .case-study-item-title {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 24px;
      text-decoration: none;
    }
  }
}

.case-study-item-container {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  .case-study-item {
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    position: relative;
    margin: 10px; // Adjust spacing as needed
    text-decoration: none;
    color: inherit;
    background: #fff; // Assuming a white background for the card
    border-radius: 8px; // Rounded corners for the card
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: auto; // Height is automatic to fit content
    max-height: 600px; // Maximum height to keep uniformity

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
      transform: translateY(-5px);
    }

    .case-study-item-img {
      width: 100%;
      height: 200px; // Keep image height consistent
      object-fit: cover;
      object-position: center;
    }

    .case-study-item-title {
      font-family: Roboto, sans-serif;
      font-weight: 700; // Bold for title
      font-size: 1.5rem; // Larger font size for title
      margin: 16px; // Space around the title
    }

    .case-study-item-desc {
      font-size: 1rem; // Smaller font size for description
      line-height: 1.6; // Line height for better readability
      color: #666; // Lighter color for description
      margin: 0 16px 16px; // Space around the description
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; // Limit to 3 lines
      -webkit-box-orient: vertical;
    }

    .case-study-published-date {
      font-size: 0.875rem; // Even smaller font size for date
      color: #999; // Even lighter color for date
      margin: 0 16px 16px; // Space around the date
      padding-bottom: 16px; // Padding at the bottom of the card
    }
  }
}
