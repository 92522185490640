#pricing {
  .pricing-header {
    padding-bottom: 2.5rem !important;

    .price-package-name {
      font-size: 30px;

      &.description {
        font-size: 20px;
        margin-top: 10px;
      }
    }

    .package-price {
      font-size: 26px;
      display: none !important;
    }
  }

  .pricing-description {
    margin-bottom: 20px;
    color: #707070;
  }

  .image-wrapper {
    margin-top: 40px;
    height: 200px;
    width: 100% !important;
    overflow: hidden;
    .process-img {
      object-fit: cover !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .time-frame {
    margin-top: 20px;
    font-size: 20px;
  }
}
