@media (max-width: 992px) {
  .nav-item {
    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
  }
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.case-studies-nav {
  margin-left: 20px !important;
}

@media (max-width: 400px) {
  .case-studies-nav {
    margin-left: unset !important;
  }
}
