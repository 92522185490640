.terms-and-conditions {
  .terms-and-conditions-text {
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 100px;
    ul {
      list-style-type: disc;
      margin-left: 25px;
      margin-bottom: 1.25em;
      li {
        padding-left: 10px;
      }
    }
    h1 {
      margin-top: 60px;
      margin-bottom: 20px;
    }
    h2 {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}
