.case-studies-wrapper {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  margin-bottom: 80px;
}

.case-studies-title {
  font-size: 2rem;
  color: #333;
  margin: 40px 0 0 0;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.case-studies-top {
  border-bottom: 2px solid #eee; /* Light gray color */
  padding-bottom: 40px; /* This will add some space between the title and the divider */
  margin-bottom: 60px; /* This will add space between the divider and the case studies */
}

.case-studies-body {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; /* This ensures that the cards stretch to fill the vertical space */
  gap: 20px; /* This adds space between the cards without the need for margin */
  margin-top: 20px;
}

.case-studies-blog-post-card {
  text-decoration: none; // Removes the underline typically found on anchor tags
  color: inherit; // Ensures text inside the link uses the inherited color
  border: 1px solid #ebebeb;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  position: relative; /* Add relative positioning */
  width: calc(
    33.333% - 20px
  ); /* Adjust the width minus the gap for three columns */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Add space at the bottom of each card */
  cursor: pointer;
}

.case-studies-blog-post-card:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(-5px); /* Move the item slightly upwards */
  text-decoration: none; // Maintains the removal of underline on hover
}

.case-studies-featured-image {
  width: 100%; // ensures the image takes the full width of the container
  height: 200px; // adjust this value based on your design needs
  object-fit: cover; // this will cover the area without stretching the image
  object-position: center; // centers the image within the element
}

.case-studies-post-content {
  padding: 15px;
  flex: 1; /* Makes the content flex to fill the space */
}

.case-studies-post-title {
  margin-top: 0;
  color: #333;
  font-size: 28px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.case-studies-post-date {
  font-size: 0.9rem;
  color: #666;
}

.case-studies-post-description {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}

/* Responsive layout for smaller screens */
@media (max-width: 1024px) {
  .case-studies-blog-post-card {
    width: calc(50% - 20px); /* Two columns for tablets */
  }
}

@media (max-width: 768px) {
  .case-studies-blog-post-card {
    width: 100%; /* Full width for mobile */
  }
}

.case-studies-loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 40px auto; /* Centers the loader */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
