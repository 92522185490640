.content-post-main {
  .main {
    background-color: #f7f7f7; // Light grey background for the main content area
    padding-bottom: 50px; // Spacing at the bottom of the main content

    .content-post-body {
      font-family: "Roboto", sans-serif; // Set the font for the entire component
      max-width: 900px; // Max width of the blog content to keep it readable
      margin: auto; // Center the blog content
      background: #fff; // White background for the blog content area
      padding: 30px 45px; // Padding inside the blog content area
      margin-top: 100px;
      margin-bottom: 100px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth

      *,
      *::before,
      *::after {
        font-family: inherit; // Inherit font from .content-post-body
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #333; // Darker text for headings
        font-family: inherit; // Inherit font from .content-post-body
        margin-top: 2rem;
      }

      p {
        color: #666; // Lighter text for paragraphs
        line-height: 1.6; // Spacing for readability
        margin-bottom: 1rem; // Spacing between paragraphs
        font-family: inherit; // Ensure paragraph tags also use Roboto
      }

      a {
        color: #1a0dab; // Traditional blue for links
        text-decoration: none; // No underline for a cleaner look

        &:hover {
          text-decoration: underline; // Underline on hover for clarity
        }
      }

      ul {
        list-style-type: disc; // Use disc for bullet points
        margin-left: 2rem; // Add left margin for indentation
        padding-left: 0; // Reset padding

        li {
          margin-bottom: 0.5rem; // Spacing between list items
        }
      }

      // Styles for ordered lists
      ol {
        list-style-type: decimal; // Use numbers
        margin-left: 2rem; // Add left margin for indentation
        padding-left: 0; // Reset padding

        li {
          margin-bottom: 0.5rem; // Spacing between list items
        }
      }
      // .content-post-featured-image {
      //   width: 100%; // Ensure the image container takes full width
      //   margin-bottom: 2rem; // Spacing before the divider

      //   img {
      //     width: 100%; // Make the image responsive
      //     height: auto; // Maintain aspect ratio
      //     object-fit: cover; // Ensure the image covers the area nicely
      //   }
      // }
      .content-post-featured-image {
        width: 100%; // Container takes full width
        height: 350px; // Fixed height for all images
        margin-bottom: 2rem; // Spacing before the divider
        overflow: hidden; // Hide parts of the image that overflow

        img {
          width: 100%; // Make the image take up the full container width
          height: 400px; // Fixed height to match the container
          object-fit: cover; // Image covers the area, maintaining aspect ratio
          object-position: center; // Center the image in the container
        }
      }
    }

    .content-post-loader,
    .content-post-error {
      text-align: center;
      padding: 50px 0;
    }

    .content-post-error {
      color: #ff6b6b; // Error messages in a standard red color
    }

    @media (max-width: 1024px) {
      .content-post-body {
        padding: 20px 35px; // Slightly reduce padding
        margin-top: 50px; // Slightly reduce top margin
        margin-bottom: 50px; // Slightly reduce bottom margin
      }

      .content-post-meta {
        flex-direction: column;
        align-items: flex-start;
      }

      .content-post-title,
      .content-post-date,
      .content-post-tag {
        text-align: left; // Align text to the left
      }

      .loader {
        margin: 200px auto;
      }
    }

    @media (max-width: 768px) {
      .content-post-body {
        padding: 15px; // Reduce padding even more
        margin-top: 30px; // Reduce top margin
        margin-bottom: 30px; // Reduce bottom margin
      }

      .content-post-title {
        font-size: 24px; // Reduce title font size
        margin-bottom: 20px; // Adjust bottom margin
      }

      .content-post-meta {
        flex-direction: column;
        align-items: flex-start;

        .content-post-tag {
          margin-bottom: 10px; // Add space below the tag
        }
      }

      .loader {
        margin: 150px auto;
      }
    }

    @media (max-width: 480px) {
      .content-post-body {
        padding: 10px; // Minimal padding for very small screens
      }

      .loader {
        margin: 100px auto;
      }
    }
  }

  .loader {
    border: 5px solid #f3f3f3;
    /* Light grey background */
    border-top: 5px solid #3498db;
    /* Blue color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 250px auto;
    /* Center the loader */
    display: block;
    /* Ensure it's not inline */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .content-post-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 25px;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-weight: 500;
  }

  .content-post-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .content-post-tag {
    background-color: #e1ecf4;
    color: #3498db;
    border-radius: 15px;
    padding: 5px 15px;
    font-size: 0.85rem;
  }

  .content-post-date {
    font-size: 1rem;
    color: #666;
  }

  .content-post-divider {
    border: 0;
    height: 2px;
    background: #eee;
    margin-bottom: 4rem;
  }
}
