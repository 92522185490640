.services-page {
  .hero-animation-img {
    // width: 70%;
    width: 450px !important;
    margin: auto;
    margin-top: 90px;
    @media (max-width: 1350px) {
      height: 370px !important;
      .animation-two {
        bottom: -140px !important;
      }
      .animation-four {
        display: none !important;
      }
    }
    @media (max-width: 1150px) {
      margin-top: 140px;
      .animation-one {
        width: 425px;
      }
      .animation-two,
      .animation-three {
        display: none !important;
      }
    }
    @media (max-width: 1050px) {
      .animation-one {
        width: 400px;
      }
    }
    @media (max-width: 992px) {
      .animation-one {
        width: 325px;
        margin-left: 40px;
      }
    }
    @media (max-width: 900px) {
      .animation-one {
        width: 275px;
      }
    }
    @media (max-width: 768px) {
      width: auto !important;
      margin: unset;
      margin-bottom: 80px;
      .animation-one {
        margin: auto;
        padding-top: 80px;
        width: 325px;
      }
    }
  }
  .single-promo {
    .promo-img {
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  }
}
