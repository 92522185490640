// import pages
@import "./pages/home.scss";
@import "./pages/portfolio.scss";
@import "./pages/services.scss";
@import "./pages/notfound.scss";
@import "./pages/privacy-policy.scss";
@import "./pages/terms-and-conditions.scss";

// import components
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/contact-modal.scss";
@import "./components/pricing.scss";
@import "./components/blog.scss";
@import "./components/content-post.scss";

// common styles below
ul.breadcrumb {
  display: none;
}
.owl-carousel {
  .owl-item {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
