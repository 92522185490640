.portfolio-page {
  .portfolio-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .portfolio-item-card {
      width: 500px;
      padding: 15px;
      margin: 20px;
      box-shadow: 0 2px 2px 2px #eaf1f4;
      .MuiTypography-h5 {
        font-size: 1.25rem;
        text-align: center;
        font-family: "Comfortaa", sans-serif;
        font-weight: 600;
      }
      .MuiCardMedia-media {
        width: 100%;
        min-height: 250px;
      }
      .MuiButtonBase-root {
        border: 0 !important;
      }
      .MuiCardActions-root {
        justify-content: center;
      }
      button:focus {
        outline: unset !important;
      }
      .portfolio-item-link {
        text-decoration: none;
      }
    }
  }
}
